<template>
  <div>
    <!-- User Info: Input Fields -->
    <b-card>
      <b-form>
        <b-card class="border">
          <h4 class="mb-1">{{ $t('organization.user_informations.title') }}</h4>
          <b-row>
            <b-col md="4">
              <app-input id="email" v-model="organizationUser.email" :label="$t('user.email')" :overlay="!isLoaded" type="email" rules="email" disabled />
            </b-col>
          </b-row>
        </b-card>
        <b-card v-if="organizationUser.airports.length" class="border mb-25">
          <b-row>
            <b-col md="12">
              <AirportOrganization v-model="organizationUser.airports" airport-switch-active-disabled :selected-organization="selectedOrganization" />
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </b-card>

    <!-- PERMISSION TABLE -->
    <rights-table
      :is-me="$route.params.organizationUser_id === selectedOrganization.userId"
      :grantable-rights="grantableRights"
      :user-data="organizationUser"
      :workrole="organizationUser.workrole"
      :editable-name="$can('ORGANIZATION_USER_EDIT')"
      :editable-rights="$can('ORGANIZATION_USER_EDIT')"
      @update:workrole="organizationUser.workrole = $event"
      @update:rights="organizationUser = { ...organizationUser, ...$event }"
    />
    <b-card>
      <div class="d-flex justify-content-end">
        <b-button variant="outline-secondary" class="mr-50" :block="$store.getters['app/smAndDown']" @click="$router.go(-1)">
          <font-awesome-icon icon="ban" class="mr-50" />
          {{ $tc('action.cancel') }}
        </b-button>

        <b-button type="submit" class="mt-0" :block="$store.getters['app/smAndDown']" @click="APIEditOrganizationUser">
          <font-awesome-icon icon="save" class="mr-50" />
          {{ $tc('action.save') }}
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { pick } from 'lodash'

import RightsTable from '@/components/RightsTable.vue'
import AirportOrganization from '@/views/airport/AirportOrganization.vue'
import UIHelper from '@/helpers/ui'
import { fetchOrganizationUserRequest, patchOrganizationUserRequest } from '@/request/globalApi/requests/organizationRequests'

export default {
  components: {
    RightsTable,
    AirportOrganization,
  },
  mixins: [UIHelper],
  data() {
    return {
      isLoaded: false,
      organizationUser: {
        id: null,
        email: '',
        status: '',
        airports: [],
        updatedAirports: [],
        rights: [],
        rightsToAdd: [],
        rightsToDelete: [],
      },
    }
  },
  computed: {
    ...mapState('auth', ['selectedOrganization', 'user']),
    ...mapState('rights', ['rightsLoading', 'rights']),
    isGrantedGiveAllRights() {
      return this.$can('OPERATOR_PARAMETER_VIEW') && this.selectedOrganization.id && this.selectedOrganization.id !== Number(this.$route.params.organization_id)
    },
    grantableRights() {
      if (this.selectedOrganization && this.selectedOrganization.rights) {
        if (this.isGrantedGiveAllRights) return this.rights
        return this.selectedOrganization.rights.map(right => right.action)
      }
      return []
    },
  },
  mounted() {
    this.APIfetchOrganizationUser()
    if (this.isGrantedGiveAllRights) this.fetchAllRights()
  },
  methods: {
    ...mapActions('rights', ['fetchAllRights']),
    APIfetchOrganizationUser() {
      fetchOrganizationUserRequest(this.selectedOrganization.id, this.$route.params.organizationUser_id)
        .then(response => {
          const { organizationUser } = response.data
          this.organizationUser = { ...this.organizationUser, ...organizationUser }
        })
        .finally(() => {
          this.isLoaded = true
        })
    },
    APIEditOrganizationUser() {
      const payload = pick(this.organizationUser, 'id', 'airports', 'rightsToAdd', 'rightsToDelete', 'status', 'workrole')
      patchOrganizationUserRequest(this.$route.params.organization_id, payload).then(() => {
        this.organizationUser.rightsToAdd = []
        this.organizationUser.rightsToDelete = []
      })
    },
  },
}
</script>
