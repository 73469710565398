var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[(_vm.displayTitle)?_c('b-card-body',[(_vm.editableName)?_c('b-card-title',[_c('b-row',[_c('b-col',{attrs:{"lg":"6","xl":"5"}},[_c('app-select',{attrs:{"id":"workroleSelect","options":_vm.userFunctions,"label-field":_vm.$t('user.workrole'),"label":"name","clearable":false,"overlay":_vm.userFunctionsLoading},on:{"option:selected":function($event){return _vm.changeSelectedWorkrole($event)}},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var name = ref.name;
return [_vm._v(" "+_vm._s(_vm._f("trans")(name))+" ")]}},{key:"option",fn:function(ref){
var name = ref.name;
return [_vm._v(" "+_vm._s(_vm._f("trans")(name))+" ")]}}],null,false,2211924973),model:{value:(_vm.selectedUserFunction),callback:function ($$v) {_vm.selectedUserFunction=$$v},expression:"selectedUserFunction"}})],1)],1),(_vm.selectedUserFunction && _vm.selectedUserFunction.id === null)?_c('b-row',[_c('b-col',{attrs:{"lg":"6","xl":"5"}},[_c('app-input',{attrs:{"id":"workroleName","label":_vm.$t('user.workrole'),"placeholder":_vm.$t('user.workrole'),"value":_vm.workrole},on:{"input":function($event){return _vm.$emit('update:workrole', $event)}}})],1)],1):_vm._e()],1):_c('b-card-title',[_c('h4',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.userData.workrole))])])],1):_vm._e(),_c('app-data-table',{attrs:{"busy":!_vm.userData.rights,"fields":_vm.fields,"hover":false,"items":_vm.localRights,"small":_vm.$store.getters['app/mdAndDown'],"all-items":"","sticky-header":"","striped":"","table-class":"mb-0 rights-table-content","table-name":"user-rights"},scopedSlots:_vm._u([_vm._l((_vm.fields),function(field){return {key:("cell(" + (field.key) + ")"),fn:function(ref){
var item = ref.item;
return [_c('div',{key:field.key,staticClass:"d-flex justify-content-center"},[_c('b-form-checkbox',{staticStyle:{"cursor":"pointer"},attrs:{"id":_vm.toUpper(((item.name) + "_" + (field.key))),"checked":item[field.key],"disabled":!_vm.editableRights || !_vm.grantableRights.includes(_vm.toUpper(((item.name) + "_" + (field.key))))},on:{"change":function($event){_vm.updateRights(_vm.toUpper(((item.name) + "_" + (field.key))), $event)}}})],1)]}}}),{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("user.right.features." + (item.name))))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }